export default defineAppConfig({
  netzo: {
    seo: {
      favicon: '/favicon.svg',
      title: 'La Tinta',
      description: 'Software de gestión de empresas.',
      ogUrl: process.env.BASE_URL!,
      // NOTE: whatsapp requires image < 300kb (freeconvert.com)
      ogImage: `${process.env.BASE_URL}/social-card.png`,
      twitterTitle: 'La Tinta',
      twitterDescription: 'Software de gestión de empresas.',
      twitterImage: `${process.env.BASE_URL}/social-card.png`,
      twitterCard: 'summary_large_image',
    },
    auth: {
      allowUserRegistration: false,
    },
    preferences: {
      // text: 'upper',
    },
    defaults: {
      currency: 'MXN',
      payment: {
        satCfdiUse: 'G03',
        satPaymentMethod: 'PPD',
        satPaymentForm: '03',
        conditions: 'NET0',
        currency: 'MXN',
      },
      legal: {
        taxId: 'IIM1704197MA',
        taxCountryCode: 'MX',
        legalName: 'IMPRESIONES IMPARTE',
        satFiscalRegime: '626',
        phone: '8123557132',
        email: 'info@latinta.mx',
        address: {
          street: 'IGNACIO ALLENDE',
          numberExterior: '115',
          neighborhood: 'EMILIANO ZAPATA',
          city: 'MONTERREY',
          state: 'NUEVO LEON',
          country: 'Mexico',
          postalCode: '64390',
        },
      },
      quotes: {
        termsAndConditions: [
          '1. El tiempo de entrega de los proyectos correrá a partir de recibir el 50% de anticipo (en caso de no contar con crédito) y la aprobación de los diseños de parte del cliente.',
          '2. Es importante que el cliente (o alguien que pueda aprobar el proyecto) esté en el sitio durante o al menos al final de la instalación para afinar detalles y aprobar el trabajo instalado. De no ser así, la aprobación se realizará con fotografías.',
          '3. Todas las actividades de adecuación del área donde se instalarán los proyectos, como limpieza de ventanal, retiro de polvo de pared, acomodo de muebles, etc., corren por cuenta y son responsabilidad del cliente.',
          '4. En el caso de ser anuncio luminoso, ES INDISPENSABLE LA ALIMENTACIÓN ELÉCTRICA AL PIE DEL ANUNCIO DE 110V. Esto quiere decir que debe de existir una toma de corriente a una distancia promedio de medio metro de donde se instalará el anuncio.',
          '5. No ofrecemos garantía en los componentes eléctricos (fuentes de poder, cables, conectores, LEDs, etc.) debido a factores externos como fluctuaciones de voltaje, instalaciones eléctricas deficientes o mal uso. El cliente es responsable de garantizar condiciones eléctricas adecuadas para el funcionamiento del letrero.',
          '6. En caso de actividades que requieran electricidad, es responsabilidad del cliente cumplir con las especificaciones detalladas en la cotización. Cualquier desviación de estas especificaciones libera a La Tinta de cualquier responsabilidad relacionada con el correcto funcionamiento del trabajo entregado.',
          '7. Durante las instalaciones, es importante mencionar que nuestra empresa no cuenta con servicios de resane y acabados. Buscamos que las maniobras sean realizadas de la mejor manera, sin embargo, cualquier daño en el área de trabajo, como perforaciones, cortes y/o raspones, no se cubren por la empresa.',
          '8. Cualquier defecto en el trabajo recibido debe ser reportado por el cliente en un plazo no mayor a 24 horas después de realizar la instalación para que podamos verificarlo en un plazo máximo de cinco días hábiles y tomar las medidas necesarias.',
          '9. En caso de que la instalación no pueda realizarse por causas ajenas a nosotros como clima o contratiempos del cliente, se reprogramará en un lapso aproximado de cinco días hábiles.',
          '10. Nos reservamos el derecho a decidir si las condiciones no son óptimas para una instalación adecuada. Si el cliente decide proceder con la instalación a pesar de que nuestra recomendación indique que las condiciones no son óptimas, el producto no contará con garantía.',
          '11. La empresa no cubre los daños causados por terceros o eventos climáticos, incluyendo vandalismo, accidentes provocados por terceros o daños causados por condiciones climáticas extremas como tormentas, vientos fuertes o inundaciones.',
          '12. Si el cliente no dispone de crédito en nuestra empresa, el pago debe realizarse en un plazo máximo de 10 días hábiles después de la instalación. De lo contrario, nos reservamos el derecho de retirar el producto instalado o aplicar intereses moratorios correspondientes al finiquito.',
          '13. La autorización de esta cotización, ya sea en persona, teléfono, correo o redes sociales, indica que las condiciones han sido leídas y aceptadas.',
        ],
      },
      pdfSettings: {
        logoPng: '/pdfmake/logo.png',
        symbolPng: '/pdfmake/symbol.png',
        watermark: {
          enabled: false,
          text: 'LATINTA',
          bold: true,
          italic: false,
        },
        images: false,
        details: false,
        pageSize: 'LETTER',
      },
    },
  },
  ui: {
    primary: 'pink',
    gray: 'neutral',
  },
})
